import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Seo from '../components/SEO/seo';
import Layout from '../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Responsible Disclosure Policy",
  "path": "/responsable-disclosure-policy"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Responsible Disclosure Policy`}</h1>
    <p>{`This page describes our policy around reporting vulnerabilities. It can be found on our website and will be promoted to stimulate the reporting of such issues.`}</p>
    <hr></hr>
    <h1>{`Report Vulnerability`}</h1>
    <p>{`We work hard on protecting our users’ money and data, it’s our top priority! But nonetheless, it could be that you find a flaw in our security. You can help us by reporting this vulnerability. `}</p>
    <p>{`Always report a vulnerability as soon as possible, and please ask us for permission before
making the vulnerability public. Making it public before we found a solution might have a serious impact on our customers. Allow us to work together on a solution first.`}</p>
    <h2>{`Reporting a vulnerability`}</h2>
    <p>{`Please share the flaw you found directly with us at `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}><strong parentName="a">{`hi@flowyour.money`}</strong></a>{`. Our team monitors this inbox and will swiftly. When reporting, you can help us with enclosing at least the following information: `}</p>
    <ul>
      <li parentName="ul">{`Severity of the flaw`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Is it Informational / Low / Medium / High / Critical? You can use the `}<a parentName="em" {...{
                "href": "https://www.first.org/cvss/calculator/3.0"
              }}>{`CVSS calculation`}</a>{` to assess the impact.`}</em></li>
        </ul>
      </li>
      <li parentName="ul">{`Please explain the vulnerability you have found and provide us with enough
information to reproduce and investigate the problem, for example:`}
        <pre parentName="li"><code parentName="pre" {...{}}>{`- Screenshot / Proof of Concept
- App version
`}</code></pre>
      </li>
    </ul>
    <aside>
    <div className="aside-icon">📩</div> Contact us at <a href="mailto:hi@flowyour.money">hi@flowyour.money</a>
    </aside>
    <h2>{`The rules of the game`}</h2>
    <p>{`You might have conducted illegal activities to discover a vulnerability. We will not report these
activities or claim damages if you have followed these rules:`}</p>
    <ul>
      <li parentName="ul">{`act responsibly with the knowledge about the vulnerability, and do not perform any
actions that go beyond what is necessary to demonstrate the flaw;`}</li>
      <li parentName="ul">{`do not share access with others;`}</li>
      <li parentName="ul">{`do not cause any damages;`}</li>
      <li parentName="ul">{`do not use a denial-of-service attack or social engineering;`}</li>
      <li parentName="ul">{`ensure that your research does not lead to an interruption of our services;`}</li>
      <li parentName="ul">{`your research should never result in bank and/or customer data becoming public;`}</li>
      <li parentName="ul">{`never place a backdoor, not even to demonstrate a vulnerability;`}</li>
      <li parentName="ul">{`never modify or delete data. In case you need to copy data, never copy more data than
strictly necessary;`}</li>
      <li parentName="ul">{`do not make any system changes;`}</li>
      <li parentName="ul">{`do not try to penetrate a system more often than necessary;`}</li>
      <li parentName="ul">{`do not use brute force techniques;`}</li>
      <li parentName="ul">{`do not use techniques that may affect the availability of our services;`}</li>
      <li parentName="ul">{`Always take applicable laws and regulations into account because you could still get in trouble with the law, even if we don’t report you to the authorities.`}</li>
    </ul>
    <h3>{`What happens when I report a vulnerability?`}</h3>
    <p>{`We will start an investigation immediately after receiving your report. We always get back
to you within a couple of days and will keep you up-to-date about our progress on solving the
problem. The time we need to solve an issue depends on the complexity of the problem. After
you have reported a problem, we ask you to refrain from making it public to give us time to
solve the issue first. We treat the received notifications confidentially. We will not share your personal details with third parties without your permission unless required to do so by law or court order.`}</p>
    <h3>{`Can I get a reward for reporting an issue?`}</h3>
    <p>{`To thank you for your help we may offer you a reward, but we are never required to offer a
reward. We only offer rewards for flaws that were unknown to us at the moment of reporting.
We will determine the type and size of the reward based on the reported issue, taking the
severity of the issue (among other things) into account. In case multiple people report the
same issue, we will only offer a reward to the first reporter. We may provide you with a free version of Flow+ for life (Life Time Deal).`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      